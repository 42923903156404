<template>
  <div
    class="mls-v-c"
    :class="{ 'mls-v-c--blur': hasBlur }"
  >
    <div
      ref="scrollable"
      class="mls-v-c__scrollable"
    >
      <div
        v-for="(item) in list"
        :key="`item-${item.id}`"
        class="mls-v-c__item"
      >
        <slot name="item" v-bind="{ item }" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MListSelectVertical',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      hasBlur: true,
      list: []
    }
  },
  mounted () {
    this.$refs.scrollable.addEventListener('scroll', this.scrollHandler)

    this.checkBlur()
  },
  beforeDestroy () {
    this.$refs.scrollable.removeEventListener('scroll', this.scrollHandler)
  },
  methods: {
    scrollHandler (e) {
      try {
        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
          this.hasBlur = false

          return
        }

        this.hasBlur = true
      } catch (e) {}
    },
    checkBlur () {
      this.list = [...this.items]

      if (!this.$refs.scrollable || !this.list.length) return

      this.$nextTick(() => {
        this.hasBlur = this.$refs.scrollable.scrollHeight > (this.$refs.scrollable.clientHeight + 1)
      })
    }
  },
  watch: {
    items: {
      immediate: true,
      handler: function () {
        this.$refs.scrollable?.scrollTo(0, 0)

        this.list = []

        this.$nextTick(() => this.checkBlur())
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~theme/css/fonts';
@import 'theme/css/breakpoints';
@import "~theme/css/px2rem";

.mls-v-c {
  position: relative;

  --mls-v-mobile-col-size: calc(100% / var(--mls-v-col-mobile, 2));
  --mls-v-desktop-col-size: calc(100% / var(--mls-v-col-desktop, 3));

  &__scrollable {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacer-8);
    width: 100%;
    max-height: var(--mls-v-mobile-max-height, 394px);
    overflow: auto;
    padding-right: var(--spacer-5);

    @media only screen and (min-width: $tablet-max) {
      max-height: var(--mls-v-desktop-max-height, 260px);
    }

    > * {
      width: calc(var(--mls-v-mobile-col-size) - var(--spacer-6));
      white-space: nowrap;

      @media only screen and (min-width: $tablet-max) {
        width: calc(var(--mls-v-desktop-col-size) - var(--spacer-6));
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-left: 3px solid var(--light-orange);
      background-clip: padding-box;

      &:hover {
        border-left-color: var(--orange);
      }
    }
  }

  @include for-mobile {
    &:after {
      position: absolute;
      content: ' ';
      bottom: -15px;
      left: 0;
      right: var(--spacer-5);
      height: var(--spacer-50);
      background: linear-gradient(0deg, #FFF 0%, #FFF 50%, rgba(255, 255, 255, 0.00) 100%);
      pointer-events: none;
      opacity: 0;
      transition: opacity 200ms;
    }

    &--blur {
      &:after {
        opacity: 1;
      }
    }
  }
}
</style>
