import i18n from '@vue-storefront/i18n'
import config from 'config'
import VueOfflineMixin from 'vue-offline/mixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import Composite from '@vue-storefront/core/mixins/composite'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import * as types from 'theme/store/checkout/store/checkout/mutation-types'
import { weightGToKg } from 'theme/filters'
import { getCheckoutSessionItemByKey, setOrderInfoIntoSessionAfterPlaceOrder } from 'theme/store/checkout/helpers/session'
import { bindTransactionToOrder } from 'theme/store/checkout/helpers/transaction'
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import { eSputnikEvent } from 'theme/helpers/es';
import { codes } from '$modules/shipping/config';
import { getNPExtend } from '$modules/shipping/helpers/shipping-helper';

export default {
  name: 'Checkout',
  mixins: [Composite, VueOfflineMixin],
  data () {
    return {
      sku: null,
      stockCheckCompleted: false,
      stockCheckOK: false,
      confirmation: null, // order confirmation from server
      lastCheck: null,
      activeSection: {
        personalDetails: true,
        package: false,
        shipping: false,
        timeSlots: false,
        payment: false,
        orderReview: false
      },
      filledSections: {
        personalDetails: false,
        package: false,
        shipping: false,
        timeSlots: false,
        payment: false,
        orderReview: false
      },
      keepHash: false,
      order: {},
      personalDetails: {},
      shipping: {},
      shippingMethod: {},
      payment: {
        paymentMethodAdditional: {}
      },
      orderReview: {},
      cartSummary: {},
      manualValidations: {
        personalDetails: false,
        shipping: false,
        payment: false,
        exciseItems: true
      },
      isValidationProcessed: false,
      isOrderPreparingToProcess: false,
      focusedField: null,
      packageInfo: null,
      lastCartHash: null
    }
  },
  computed: {
    ...mapState({
      productsInCart: state => state.cart.cartItems
    }),
    ...mapGetters({
      isVirtualCart: 'cart/isVirtualCart',
      isThankYouPage: 'checkout/isThankYouPage',
      getProductComments: 'cart/getProductComments',
      getCurrentTimeSlot: 'checkout/getCurrentTimeSlot',
      totals: 'cart/getTotals',
      itemsQuantity: 'cart/getItemsQuantity',
      nonGiftItemsQuantityWithQTY: 'cart/getNonGiftItemsQuantityWithQTY',
      discounts: 'cart/getDiscounts',
      coupon: 'cart/getCoupon',
      cartStates: 'cart/getCartStates',
      shippingDetails: 'shipping-module/fallbackShippingDetails',
      isLoadedShippingDetails: 'shipping/isLoadedShippingDetails',
      isCartHasExciseItems: 'cart/isCartHasExciseItems',
      getActiveStep: 'checkout/getActiveStep',
      shippingTimeSlots: 'checkout/getShippingTimeSlots',
      getShops: 'shipping-module/getShopList',
      getCurrentCartHash: 'cart/getCurrentCartHash',
      getPaymentMethods: 'checkout/getPaymentMethods',
      isLoggedIn: 'user/isLoggedIn',
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    ...mapState({
      isCartSync: state => state.cart.isSyncing,
      isCartSyncTotal: state => state.cart.isSyncingTotal,
      getCurrentShipping: state => state['shipping-module'].current
    }),
    paymentMethod () {
      return this.payment?.paymentMethod
    },
    getWeightFromTotalSegments () {
      const weightSegment = this.$store.state.cart?.platformTotalSegments?.find(e => e.code === 'weight') || {}
      return weightGToKg(weightSegment?.value || 0)
    },
    isDarkstore () {
      const tmsId = this.shippingDetails?.shop?.tms_id
      const shop = this.getShops?.find((i) => i.tms_id === tmsId) || this.shippingDetails?.shop

      return Boolean(+shop?.is_darkstore)
    },
    isCartLoading () {
      return this.isCartSync || this.isCartSyncTotal
    },
    newPostValid () {
      if (!this.isNewPost) return true

      const items = this.productsInCart.filter(i => !i.forNewPost)

      return !items.length
    },
    validationResults () {
      return {
        ...this.manualValidations,
        shippingMethod: this.cartStates.shippingMethod.valid,
        productStockReplacements: !this.cartStates.productStock.replacements.length,
        shippingTimeSlots: this.cartStates.shippingTimeSlots.valid,
        maxWeight: this.cartStates.maxWeight.valid,
        newPostValidation: this.newPostValid
      }
    },
    paymentMethodFromShop () {
      const shippingMethod = this.getCurrentTimeSlot?.delivery_method

      if (!shippingMethod) return

      const { shop } = this.shippingDetails || {};
      if (!shop?.id) return

      const foundShop = this.getShops.find(s => s.id === shop.id) || shop;

      const deliveryMethod = foundShop?.delivery_methods.find(d => d.key === shippingMethod);

      return deliveryMethod?.payment_methods;
    },
    availablePaymentMethods () {
      const paymentMethod = this.paymentMethodFromShop

      return paymentMethod || this.shippingDetails.availablePaymentMethods || []
    },
    paymentMethods () {
      return this.getPaymentMethods.filter(method => this.availablePaymentMethods.includes(method.code)).reverse()
    }
  },
  async beforeMount () {
    await this.$store.dispatch('checkout/load')
    this.checkOnMount();
    this.$store.dispatch('checkout/setModifiedAt', Date.now())
    // TODO: Use one event with name as apram
    this.$bus.$on('cart-after-update', this.onCartAfterUpdate)
    this.$bus.$on('cart-after-delete', this.onCartAfterUpdate)
    this.$bus.$on('after-cart-merge', this.onCartAfterMerge)
    this.$bus.$on('cart-after-connect', this.onCartAfterConnect)
    this.$bus.$on('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$on('checkout-after-shippingDetails', this.onAfterShippingDetails)
    this.$bus.$on('checkout-after-timeSlots', this.onAfterTimeSlots)
    this.$bus.$on('checkout-after-package', this.onAfterPackage)
    this.$bus.$on('checkout-after-paymentDetails', this.onAfterPaymentDetails)
    this.$bus.$on('checkout-after-cartSummary', this.onAfterCartSummary)
    this.$bus.$on('checkout-before-placeOrder', this.onBeforePlaceOrder)
    this.$bus.$on('checkout-do-placeOrder', this.onDoPlaceOrder)
    this.$bus.$on('checkout-before-edit', this.onBeforeEdit)
    this.$bus.$on('order-after-placed', this.onAfterPlaceOrder)
    this.$bus.$on('checkout-before-shippingMethods', this.onBeforeShippingMethods)
    this.$bus.$on('checkout-after-shippingMethodChanged', this.onAfterShippingMethodChanged)
    this.$bus.$on('checkout-after-validationError', this.focusField)
    this.$bus.$on('update-validation-result', this.updateValidationResult)
    this.$bus.$on('checkout-failed-place', this.failedPlaceOrder)
    this.$bus.$on('user-after-logout', this.resetCheckout)
    this.$bus.$on('update-changed-cart', this.onUpdateChangedCart)
    this.$bus.$on('shipping-has-dropped', this.resetToShipping)
    this.$bus.$on('timeslot-has-dropped', this.resetToTimeslot)
    this.$bus.$on('payment-has-dropped', this.resetToPayment)
    this.$bus.$on('checkout-filled-clear', this.onFilledClear)
    const storeView = currentStoreView()
    let country = this.$store.state.shipping.shippingDetails.country
    if (!country) country = storeView.i18n.defaultCountry
    this.$bus.$emit('checkout-before-shippingMethods', country)
    this.$bus.$emit('checkout-after-load')
    document.addEventListener('visibilitychange', this.checkOnMount);
  },
  beforeDestroy () {
    this.$store.dispatch('checkout/setModifiedAt', 0) // exit checkout
    this.$bus.$off('cart-after-update', this.onCartAfterUpdate)
    this.$bus.$off('cart-after-delete', this.onCartAfterUpdate)
    this.$bus.$off('after-cart-merge', this.onCartAfterMerge)
    this.$bus.$off('cart-after-connect', this.onCartAfterConnect)
    this.$bus.$off('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$off('checkout-after-shippingDetails', this.onAfterShippingDetails)
    this.$bus.$off('checkout-after-timeSlots', this.onAfterTimeSlots)
    this.$bus.$off('checkout-after-package', this.onAfterPackage)
    this.$bus.$off('checkout-after-paymentDetails', this.onAfterPaymentDetails)
    this.$bus.$off('checkout-after-cartSummary', this.onAfterCartSummary)
    this.$bus.$off('checkout-before-placeOrder', this.onBeforePlaceOrder)
    this.$bus.$off('checkout-do-placeOrder', this.onDoPlaceOrder)
    this.$bus.$off('checkout-before-edit', this.onBeforeEdit)
    this.$bus.$off('order-after-placed', this.onAfterPlaceOrder)
    this.$bus.$off('checkout-before-shippingMethods', this.onBeforeShippingMethods)
    this.$bus.$off('checkout-after-shippingMethodChanged', this.onAfterShippingMethodChanged)
    this.$bus.$off('checkout-after-validationError', this.focusField)
    this.$bus.$off('update-validation-result', this.updateValidationResult)
    this.$bus.$off('shipping-has-dropped', this.resetToShipping)
    this.$bus.$off('timeslot-has-dropped', this.resetToTimeslot)
    this.$bus.$off('payment-has-dropped', this.resetToPayment)
    this.$bus.$off('checkout-failed-place', this.failedPlaceOrder)
    this.$bus.$off('user-after-logout', this.resetCheckout)
    this.$bus.$off('update-changed-cart', this.onUpdateChangedCart)
    this.$bus.$off('checkout-filled-clear', this.onFilledClear)
    document.removeEventListener('visibilitychange', this.checkOnMount);
  },
  watch: {
    '$route': {
      immediate: true,
      handler: async function (newValue, oldVal) {
        this.activateHashSection(newValue, oldVal)
      }
    },
    'OnlineOnly': 'onNetworkStatusCheck',
    getActiveStep (currentStep) {
      if (currentStep === 'orderReview') this.orderReviewStepHandler()
    },
    isLoadedShippingDetails: {
      immediate: true,
      handler (newValue) {
        if (!newValue) return

        return this.checkShopsList()
      }
    },
    paymentMethods: {
      immediate: true,
      handler: function () {
        this.checkPayment()
      }
    },
    isDarkstore: {
      handler: function (val, oldVal) {
        if (val) {
          this.filledSections.package = true

          return
        }

        if (!val && oldVal) {
          this.filledSections.package = false
          this.activateSection('package')
        }
      }
    },
    isNewPost: {
      handler: function (val, oldVal) {
        if (val) {
          this.filledSections.timeSlots = true

          return
        }

        if (!val && oldVal) {
          this.filledSections.timeSlots = true
          this.activateSection('timeSlots')
        }
      }
    }
  },
  methods: {
    ...mapActions({
      spawnNotification: 'notification/spawnNotification'
    }),
    async checkShopsList (notImportant = true) {
      try {
        if (!this.shippingDetails.userFilledShippingInfo) {
          this.activateSection('shipping', 'edit')
          return false
        }

        const { check } = await this.$store.dispatch('shipping-module/loadShopList', {
          shopId: this.shippingDetails.shop.id,
          notImportant,
          checkShop: true
        })

        return check
      } catch (e) {
        return true
      }
    },
    onCartAfterUpdate () {
      if (this.$store.state.cart.cartItems.length === 0) {
        this.notifyEmptyCart()
        this.$router.push(this.localizedRoute('/'))
        return
      }

      return this.checkStocksAndPrices()
    },
    async onUpdateChangedCart (ctx) {
      await this.checkStocksAndPrices()

      return this.checkTimeSlots(ctx)
    },
    async checkOnMount () {
      try {
        if (document.visibilityState !== 'visible') return

        return this.checkConditionsByStep()
      } catch (e) {}
    },
    checkPayment () {
      if (!this.activeSection.orderReview) return

      const availablePaymentMethods = this.availablePaymentMethods || []

      if (availablePaymentMethods.includes(this.paymentMethod)) return

      this.resetToPayment()
    },
    async checkConditionsByStep () {
      const step = ['orderReview']

      if (!step.includes(this.getActiveStep)) return this.checkStocksAndPrices()

      await this.checkConditionsForPlacingOrder()
    },
    async onAfterShippingMethodChanged (payload) {
      await this.$store.dispatch('cart/syncTotals', { forceServerSync: true, methodsData: payload })
      this.shippingMethod = payload
    },
    onBeforeShippingMethods () {
      this.$store.dispatch('cart/syncTotals', { forceServerSync: true })
      this.$forceUpdate()
    },
    async onAfterPlaceOrder (payload) {
      this.setOrderConfirmationData(payload.confirmation)
      this.storePlaceOrderInfo(payload.order);
      this.logOrderDetails(payload.order);

      if (payload.confirmation.hold_data) {
        await bindTransactionToOrder(payload.confirmation)
      }

      await this.completeOrderProcess()
    },
    setOrderConfirmationData (confirmation) {
      this.confirmation = this.prepareOrderConfirmationData(confirmation);
      this.$store.commit(`checkout/${types.CHECKOUT_SET_ORDER_CONFIRMATION_INFO}`, this.confirmation);
    },
    storePlaceOrderInfo (order) {
      this.$store.commit(`checkout/${types.CHECKOUT_PLACE_ORDER}`, order);
      setOrderInfoIntoSessionAfterPlaceOrder({
        order,
        confirmation: this.confirmation,
        summary: this.$store.getters['checkout/getSummary'],
        shipping: this.$store.getters['checkout/getShippingDetails'],
        shippingTimeSlots: this.$store.getters['checkout/getShippingTimeSlots'],
        paymentMethods: this.$store.getters['checkout/getPaymentMethods'],
        paymentDetails: this.$store.getters['checkout/getPaymentDetails'],
        personalDetails: this.$store.getters['checkout/getPersonalDetails']
      });
    },
    logOrderDetails (order) {
      Logger.debug(order)();
    },
    async completeOrderProcess () {
      await this.goToThankYouPage();
      await this.$store.dispatch('checkout/clearCheckoutSession');
    },
    async goToThankYouPage () {
      await this.$store.dispatch('checkout/setThankYouPage', true)
      this.$bus.$emit('notification-progress-stop')
      await this.$router.push(this.localizedRoute('/thank-you-page'))
    },
    onBeforeEdit (section) {
      this.activateSection(section, 'edit')
    },
    async validateOrder () {
      this.isOrderPreparingToProcess = true;
      const validationResult = await this.checkConditionsForPlacingOrder();
      this.isOrderPreparingToProcess = false;

      if (!validationResult) {
        if (!this.shippingTimeSlots.length || !this.getCurrentTimeSlot) {
          this.activateSection('timeSlots', 'edit');
        }

        this.$bus.$emit('placing-an-order', true);
        sendToLogs(groups.Checkout, 'checkout:onPlaceOrder:validation', validationResult);
      }

      return validationResult;
    },
    async handleHoldPaymentMethod () {
      const pendingPageUrl = this.localizedRoute('/payment-redirect');
      let newWindow = window.open(pendingPageUrl, '_blank');
      const { result } = await this.onDoPlaceOrder();
      const url = result?.hold_data[0]?.redirect_link;
      if (url) newWindow.location.replace(url);
    },
    async onBeforePlaceOrder () {
      try {
        await this.$store.dispatch('ui/setOrderPreparingToProcess', true)

        const isValidOrder = await this.validateOrder();

        if (isValidOrder) {
          if (this.paymentMethod === 'HOLD') {
            await this.handleHoldPaymentMethod();
          } else {
            await this.onDoPlaceOrder();
          }
        }
      } catch (e) {
        sendToLogs(groups.Checkout, 'checkout:onPlaceOrder:error', { message: e.message });
      } finally {
        await this.$store.dispatch('ui/setOrderPreparingToProcess', false)
      }
    },
    resetToShipping () {
      this.resetCheckout(['personalDetails', 'package'])
      this.activateSection('shipping', 'edit')
      this.$bus.$emit('timeslot-dropped')
    },
    resetToTimeslot () {
      if (this.isNewPost) this.resetToShipping()

      this.resetCheckout(['personalDetails', 'package', 'shipping'])
      this.activateSection('timeslot', 'edit')
      this.$bus.$emit('timeslot-dropped')
    },
    resetToPayment () {
      this.resetCheckout(['personalDetails', 'package', 'shipping', 'timeSlots'])
      this.activateSection('payment', 'edit')
      this.$bus.$emit('payment-dropped')
    },
    resetCheckout (keep = []) {
      const novaKeep = this.isNewPost ? ['timeSlots', 'package'] : []

      for (let section in this.filledSections) {
        if (keep.includes(section)) continue
        if (novaKeep.includes(section)) continue

        this.filledSections[section] = false
      }
    },
    prepareSummary () {
      const prices = () => {
        return this.totals.reduce((result, price) => {
          result[price.code] = price.value;
          return result;
        }, {});
      }
      const totalWeight = () => {
        const weight = this.getWeightFromTotalSegments
        return `${weight} ${this.$t('kg')}`
      }
      return {
        prices: prices(),
        totalWeight: totalWeight(),
        itemsQuantity: this.itemsQuantity,
        discounts: this.discounts,
        nonGiftItemsQuantityWithQTY: this.nonGiftItemsQuantityWithQTY,
        coupon: this.coupon.code || ''
      }
    },
    onAfterCartSummary () {
      const summary = this.prepareSummary()
      this.cartSummary = summary
      this.$store.commit(`checkout/${types.CHECKOUT_SET_SUMMARY}`, summary)
    },
    onDoPlaceOrder () {
      if (this.$store.state.cart.cartItems.length === 0) {
        this.notifyEmptyCart()
        this.$router.push(this.localizedRoute('/'))
      } else {
        this.onAfterCartSummary()
        return this.placeOrder()
      }
    },
    onAfterPaymentDetails (receivedData, validationResult) {
      this.payment = receivedData.data
      this.manualValidations.payment = validationResult
      this.filledSections.payment = true
      this.activateSection('orderReview', 'default')
      this.savePaymentDetails()

      if (!this.isCartHasExciseItems) {
        this.onAfterExciseItems(true)
      }
    },
    onAfterExciseItems (validationResult) {
      this.manualValidations.exciseItems = validationResult
    },
    onAfterShippingDetails (receivedData, validationResult) {
      this.shipping = receivedData.data
      this.manualValidations.shipping = validationResult
      const storeView = currentStoreView()
      storeView.tax.defaultCountry = this.shipping.country
      this.filledSections.shipping = true
      this.saveShippingDetails()
      const nextStep = this.isNewPost ? 'payment' : 'timeSlots'
      this.activateSection(nextStep, receivedData.onCheckoutLoad ? 'load' : 'default')
    },
    onAfterTimeSlots ({ onCheckoutLoad }) {
      this.filledSections.timeSlots = true

      if (this.isDarkstore) {
        this.onAfterPackage({
          data: {
            packaging: 'min_packages',
            replace: 'do_no_replace'
          },
          onCheckoutLoad
        })

        return
      }

      this.activateSection('payment', onCheckoutLoad ? 'load' : 'default')
    },
    onAfterPersonalDetails (receivedData, validationResult) {
      this.personalDetails = receivedData.data
      this.manualValidations.personalDetails = validationResult
      this.filledSections.personalDetails = true
      if (this.isDarkstore) this.filledSections.package = true
      if (this.isNewPost) this.filledSections.timeSlots = true
      this.activateSection('package', receivedData.onCheckoutLoad ? 'load' : 'default')
      this.savePersonalDetails()
      this.focusedField = null
    },
    onAfterPackage ({ data, onCheckoutLoad }) {
      this.packageInfo = data
      this.filledSections.package = true
      this.activateSection('shipping', onCheckoutLoad ? 'load' : 'default')
      this.savePackagingDetails()
    },
    onNetworkStatusCheck (isOnline) {
      this.checkConnection(isOnline)
    },
    activateHashSection () {
      if (isServer) return

      const urlStep = window.location.hash.replace('#', '')

      if (this.activeSection.hasOwnProperty(urlStep) && this.activeSection[urlStep] === false) {
        this.keepHash = true
        this.activateSection(urlStep, 'edit', false)
      } else if (urlStep === '') {
        this.activateSection('personalDetails')
      }
    },
    checkConnection (isOnline) {
      if (!isOnline) {
        this.notifyNoConnection()
      }
    },
    getNextSection (section, flag) {
      for (let key in this.filledSections) {
        const isFilled = this.filledSections[key]
        const isEdit = flag === 'edit' && section === key

        if (!isFilled || isEdit) return key
      }

      return section
    },
    activateSection (sectionToActivate, flag = 'default', resetHashFreeze = true) {
      if (flag === 'load' && this.keepHash) return

      if (resetHashFreeze) this.keepHash = false

      const nextSection = this.getNextSection(sectionToActivate, flag)

      for (let section in this.activeSection) {
        this.activeSection[section] = false
      }
      this.activeSection[nextSection] = true
      this.$store.commit(`checkout/${types.CHECKOUT_SET_ACTIVE_STEP}`, nextSection)

      if (!isServer) {
        const newHash = '#' + nextSection;
        const newUrl = new URL(window.location.href);
        newUrl.hash = newHash;
        window.history.replaceState({}, document.title, newUrl);
      }
    },
    // This method checks if there exists a mapping of chosen payment method to one of Magento's payment methods.
    getPaymentMethod () {
      let paymentMethod = this.payment.paymentMethod
      if (config.orders.payment_methods_mapping.hasOwnProperty(paymentMethod)) {
        paymentMethod = config.orders.payment_methods_mapping[paymentMethod]
      }
      return paymentMethod
    },
    getProductComment (sku) {
      const comments = this.getProductComments
      const item = comments.find(item => item.sku === sku)
      return item ? item.comment : ''
    },
    prepareProductsData (items) {
      return items.map((item) => {
        return {
          sku: item.sku,
          qty: item.qty,
          name: item.name || item.title,
          price: item.price,
          extension_attributes: {
            markdown_id: (item?.markdown_id || item?.extension_attributes?.markdown_id),
            comment: this.getProductComment(item.sku)
          }
        }
      })
    },
    prepareOrder () {
      const street = this.shippingDetails.streetAddress?.split(',') || [this.shipping.street, this.shipping.houseNumber]

      const methodCode = this.isNewPost
        ? codes.newPost.toLowerCase()
        : this.getCurrentTimeSlot?.delivery_method?.toLowerCase()

      const officeNumber = this.isNewPost ? this.shippingDetails.newPostShop.number : this.shipping.officeNumber

      // !this.shipping.npPhone ||
      // !this.shipping.npFirstname ||
      // !this.shipping.npLastname ||
      // !this.shipping.npMiddlename

      const fullName = (
        this.isNewPost ? [
          this.shipping.npLastname,
          this.shipping.npFirstname,
          this.shipping.npMiddlename
        ] : [
          this.personalDetails.firstname,
          this.personalDetails.middlename
        ]
      ).filter(i => !!i).join(' ')

      const phone = this.isNewPost ? this.shipping.npPhone : this.personalDetails.phone

      this.order = {
        user_id: this.$store.state.user.current ? this.$store.state.user.current.id.toString() : '',
        cart_id: this.$store.state.cart?.cartServerToken || '',
        products: this.prepareProductsData(this.$store.state.cart.cartItems),
        addressInformation: {
          billingAddress: {
            city: this.shipping.city,
            country_id: 'UA',
            firstname: fullName,
            lastname: '',
            telephone: phone,
            postcode: this.shipping.postcode,
            street: street
          },
          shipping_method_code: methodCode,
          shipping_carrier_code: 'varus',
          payment_method_code: this.getPaymentMethod(),
          payment_method_additional: {
            card_mask: this.payment.paymentMethodAdditional?.card ? this.payment.paymentMethodAdditional.card.card_mask : '',
            type: this.payment.paymentMethod
          },
          shippingExtraFields: {
            comment: '',
            shop_id: this.shippingDetails.shopId
          }
        }
      }
      if (!this.isVirtualCart) {
        const shippingAddress = {
          city: this.shipping.city,
          country_id: 'UA',
          firstname: this.shipping.isOtherRecipient ? this.shipping.otherRecipientName : fullName,
          lastname: '',
          telephone: this.shipping.isOtherRecipient ? this.shipping.phone : phone,
          postcode: this.shipping.postcode,
          street: street,
          extension_attributes: {
            delivery_sku: this.sku,
            premises_type: this.shipping.premisesType.toLowerCase(),
            door_postal_house_number: this.shipping.doorPostalHouseNumber,
            entrance_number: this.shipping.entranceNumber,
            flour: this.shipping.flour,
            house_building: this.shipping.houseBuilding,
            house_number: this.shipping.houseNumber,
            intercom_code: this.shipping.intercomCode,
            latitude: this.shipping.latitude,
            longitude: this.shipping.longitude,
            office_number: officeNumber,
            company_name: this.shipping.companyName,
            comment: this.shipping.comment,
            replacement: null,
            packaging: null,
            packaging_sku: '',
            timeslot: this.getCurrentTimeSlot
          }
        }

        if (this.getCurrentShipping.npShop) {
          shippingAddress.extension_attributes.np_warehouse = getNPExtend(this.getCurrentShipping)
        }

        this.order.addressInformation.shippingAddress = shippingAddress
      }

      if (!this.isDarkstore) {
        this.order.addressInformation.shippingAddress.extension_attributes.packaging_sku = this.packageInfo.product?.sku || ''
        this.order.addressInformation.shippingAddress.extension_attributes.packaging = this.packageInfo.packaging
        this.order.addressInformation.shippingAddress.extension_attributes.replacement = this.packageInfo.replace
        this.order.addressInformation.shippingExtraFields.comment = this.packageInfo.comment
      }

      return this.order
    },
    placeOrder () {
      this.checkConnection({ online: typeof navigator !== 'undefined' ? navigator.onLine : true })

      const order = this.prepareOrder()

      sendToLogs(
        groups.Checkout,
        'checkout:order:payload',
        order
      )

      return this.$store.dispatch('checkout/placeOrder', { order })
    },
    savePersonalDetails () {
      this.$store.dispatch('checkout/savePersonalDetails', this.personalDetails)
    },
    saveShippingDetails () {
      this.$store.dispatch('checkout/saveShippingDetails', this.shipping)
    },
    savePaymentDetails () {
      this.$store.dispatch('checkout/savePaymentDetails', this.payment)
    },
    savePackagingDetails () {
      this.$store.dispatch('checkout/savePackagingDetails', this.packageInfo)
    },
    focusField (fieldName) {
      if (fieldName === 'password') {
        window.scrollTo(0, 0)
        this.activateSection('personalDetails')
        this.focusedField = fieldName
      }
      if (fieldName === 'email-address') {
        window.scrollTo(0, 0)
        this.activateSection('personalDetails')
        this.focusedField = fieldName
      }
    },
    updateValidationResult (obj) {
      for (const prop in obj) {
        this.manualValidations[prop] = obj[prop]
      }
    },
    async failedPlaceOrder () {
      this.$store.commit('ui/setLoader', false)

      return Promise.all([
        this.spawnNotification({
          type: 'error',
          message: this.$i18n.t(this.isNewPost ? 'Can\'t place order nova' : 'Can\'t place order'),
          action1: { label: this.$i18n.t('OK') }
        }),
        this.checkConditionsForPlacingOrder(true)
      ])
    },
    async checkTimeSlots (ctx) {
      try {
        if (this.isNewPost) return

        if (ctx?.shippingChanged) return

        const sessionData = getCheckoutSessionItemByKey('currentTimeSlot')
        const currentTimeSlot = this.getCurrentTimeSlot?.id ? this.getCurrentTimeSlot : sessionData

        const isValid = await this.$store.dispatch('cart/checkTimeSlots', { currentTimeSlot })

        if (!isValid && this.filledSections.shipping) {
          this.activateSection('timeSlots', 'edit')
        }
      } catch (e) {
        sendToLogs(
          groups.Checkout,
          'checkout:onTimeSlot:error',
          { message: e.message }
        )

        Logger.error(e)()
      }
    },
    onFilledClear (section) {
      if (!this.filledSections.hasOwnProperty(section)) return

      this.filledSections[section] = false
    },
    async checkStocksAndPrices () {
      try {
        this.checkPayment()
        await this.$store.dispatch('cart/checkCartStateProductStockAndProductPrices')
      } catch (e) {
        Logger.error(e)()
      }
    },
    async checkConditionsForPlacingOrder (force = false) {
      try {
        const houseNumber = !!(this.shipping.houseNumber || '')?.trim()?.length

        if (!houseNumber) {
          await this.$store.dispatch('shipping-module/clearShipping')
        }

        const valid = [
          houseNumber,
          !!this.validationResults?.productStockReplacements,
          this.lastCheck > Date.now(),
          !force
        ]

        if (!this.isNewPost) {
          valid.push(
            !!this.validationResults?.shippingTimeSlots
          )
        } else if (
          !this.shipping.npPhone ||
          !this.shipping.npFirstname ||
          !this.shipping.npLastname ||
          !this.shipping.npMiddlename
        ) {
          this.resetToShipping()
          return
        }

        if (valid.includes(false)) {
          const hasShopList = await this.checkShopsList(false)

          if (!hasShopList) {
            return false
          }

          await this.checkStocksAndPrices()
          await this.checkTimeSlots()
        }
      } catch (e) {
        Logger.error(e)()
      } finally {
        this.lastCheck = Date.now() + config.checkout.minutesToRecheck * 60 * 1000
      }

      const timeSlotCheck = !this.isNewPost
        ? (!!this.validationResults.shippingTimeSlots)
        : true

      return (
        this.validationResults.shippingMethod &&
        timeSlotCheck &&
        this.validationResults.productStockReplacements
      )
    },
    async orderReviewStepHandler () {
      this.isValidationProcessed = true
      const validationResult = await this.checkConditionsForPlacingOrder()
      if (!validationResult) {
        this.$bus.$emit('placing-an-order', false)

        if (!this.shippingTimeSlots.length || !this.getCurrentTimeSlot) {
          this.activateSection('timeSlots', 'edit')
        }
      }
      this.isValidationProcessed = false
    },
    prepareOrderConfirmationData (data) {
      return {
        backendOrderId: data.order_id,
        orderNumber: data.order_id
      }
    },
    onCartAfterMerge () {
      this.updateStatusCart()
    },
    onCartAfterConnect () {
      this.updateStatusCart()
    },
    updateStatusCart () {
      const currentCartHash = this.getCurrentCartHash

      if (this.lastCartHash === currentCartHash) {
        return
      }

      eSputnikEvent('StatusCart', { productsInCart: this.productsInCart, currentCartHash: this.getCurrentCartHash })
      this.lastCartHash = currentCartHash
    }
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || i18n.t('Checkout'),
      meta: this.$route.meta.description ? [{ vmid: 'description', name: 'description', content: this.$route.meta.description }] : []
    }
  },
  asyncData ({ context }) { // this is for SSR purposes to prefetch data
    return new Promise((resolve) => {
      if (context) context.output.cacheTags.add(`checkout`)
      resolve()
    })
  }
}
