<template>
  <ASmoothReflow class="m-price-summary">
    <SfProperty
      v-if="nonGiftItemsQuantityWithQTY"
      :name="$t('Cart amount total')"
      :value="getCartTotal | price"
      class="sf-property--full-width property property--total-items"
      :class="{'sf-property--large': isLarge}"
    />

    <SfProperty
        v-if="coupon"
        :name="coupon.label"
        :value="coupon.amount | price"
        class="sf-property--full-width property"
        :class="{'sf-property--large': isLarge}"
    />

    <SfProperty
      v-if="isNewPost"
      :value="$t('Delivery Plan')"
      class="sf-property--full-width property sf-property--np"
      :class="{
        'sf-property--large': isLarge,
        'sf-property--over': isOverweight
      }"
    >
      <template slot="name">
        <span class="sf-property__name">
          {{ deliveryLabel }} {{ totalWeightWithTranslate }}
          <span v-if="maxWeight" class="sf-property__name--sub">/ {{ maxWeight }}</span>
        </span>
      </template>
    </SfProperty>
    <SfProperty
      v-else-if="(prices.shipping || prices.shipping === 0) && deliveryLabel"
      :value="prices.shipping | price"
      class="sf-property--full-width property"
      :class="{
        'sf-property--large': isLarge,
        'sf-property--over': isOverweight
      }"
    >
      <template slot="name">
        <span class="sf-property__name">
          {{ deliveryLabel }} {{ totalWeightWithTranslate }}
          <span v-if="maxWeight" class="sf-property__name--sub">/ {{ maxWeight }}</span>
        </span>
      </template>
    </SfProperty>

    <SfDivider class="divider" />

    <SfProperty
      :name="$t('Total')"
      :value="prices.grand_total | price"
      class="sf-property--full-width property property--grand-total"
      :class="{'sf-property--large': isLarge}"
    />
  </ASmoothReflow>
</template>

<script>
import { mapGetters } from 'vuex';
import { SfProperty, SfDivider } from '@storefront-ui/vue';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow';
import { weightGToKg } from 'theme/filters';

export default {
  name: 'MPriceSummary',
  components: {
    SfProperty,
    SfDivider,
    ASmoothReflow
  },
  props: {
    isLarge: {
      type: Boolean,
      default: false
    },
    totalWeight: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    ...mapGetters({
      totals: 'cart/getTotals',
      getCartTotal: 'cart/getCartTotal',
      shippingDetails: 'shipping/getShippingDetails',
      cartStates: 'cart/getCartStates',
      nonGiftItemsQuantityWithQTY: 'cart/getNonGiftItemsQuantityWithQTY',
      discounts: 'cart/getDiscounts',
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    coupon () {
      return this.discounts.find(i => i.code === 'coupon') || null
    },
    deliveryLabel () {
      return this.$t(this.shippingDetails?.shippingMethodType?.toLowerCase() || '')
    },
    maxWeightPure () {
      const maxWeightInGrams = this.shippingDetails.deliveryMethod?.max_weight || 0;

      return weightGToKg(maxWeightInGrams)
    },
    maxWeight () {
      return this.maxWeightPure ? `${this.maxWeightPure} ${this.$t('kg')}` : null
    },
    isOverweight () {
      if (this.maxWeightPure === 0) return true

      return this.totalWeight > this.maxWeightPure
    },
    totalWeightWithTranslate () {
      return `${this.totalWeight} ${this.$t('kg')}`
    },
    prices () {
      return this.totals.reduce((result, price) => {
        result[price.code] = price.value;
        return result;
      }, {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";

.m-price-summary {
  ::v-deep {
    .property {
      margin: 0 0 var(--spacer-10);
      --property-name-content: none;
      --property-name-font-size: var(--font-size-16);
      --property-value-font-size: var(--font-size-16);
      --property-name-color: var(--black);
      --property-value-color: var(--black);
      --property-name-font-weight: var(--font-normal);
      --property-value-font-weight: var(--font-normal);
      --property-name-font-line-height: 1.2;

      &--invalid {
        .sf-property__name, .sf-property__value {
          color: var(--red-main);
          font-weight: 600;

          span {
            font-weight: 400;
          }
        }
      }

      &--weight {
        margin: 0 0 var(--spacer-15);
        --property-name-font-size: var(--font-sm);
        --property-value-font-size: var(--font-sm);
        --property-name-color: var(--dark-gray);
        --property-value-color: var(--dark-gray);
      }

      &--grand-total {
        padding-top: var(--spacer-5);

        @media (max-width: $mobile-max) {
          margin-bottom: 0;
        }

        span {
          @include header-title(22, 22);
        }
      }

      @include for-desktop {
        &__total {
          padding: var(--spacer-base) 0 0 0;
        }
      }
    }

    .divider {
      --divider-border-color: var(--c-white);
      --divider-width: 100%;
      --divider-margin: 0 0 var(--spacer-5);
      --divider-border-width: 0;
    }
  }

  .sf-property--over {
    .sf-property__name {
      color: var(--red-main);
      font-weight: 600;
    }
  }

  .sf-property__name--sub {
    color: var(--dark-gray);
    font-size: var(--font-size-12);
    font-weight: 400;
  }
}
</style>
